let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-wecomplai-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-wecomplai-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://7bys4x7ugzg2hodfwy5uf4ugdu.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://iozh8ik6kc.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://nrsmj7ztbk.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.wecomplai.forwoodsafety.com",
        WEBSOCKET: "wss://hmys0usw60.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_ECwpt0LbB",
        IDENTITY_POOL_ID: "us-west-2:0627a3e7-66ca-4204-aa74-fa9b3f667831",
        USERPOOL_HOSTED_DOMAIN: "forwood-wecomplai-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.wecomplai.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.wecomplai.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::853705142524:role/prod-CA"
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.2",
        HOSTNAME: "id.wecomplai.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".wecomplai.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "fcc781b5-2014-42d9-994c-1480920054aa",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.wecomplai.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
